import styled from "styled-components";
import {
  space,
  color,
  layout,
  typography,
  flexbox,
  variant,
  position,
} from "styled-system";
import propTypes from "@styled-system/prop-types";
import { themeGet } from "@styled-system/theme-get";
import { darken } from "polished";
import { EXCLUDED_CSS_PROP } from "../../const";

const Button = styled.div.withConfig({
  shouldForwardProp: prop => !EXCLUDED_CSS_PROP.includes(prop),
})`
  position: relative;
  padding-left: ${props => (props.icon ? "68px" : "43px")};
  padding-right: 43px;
  text-align: center;
  text-decoration: none;
  border: 0;
  text-transform: uppercase;
  border-radius: 100px;
  transition: background-color ${themeGet("times.transition")};
  font-family: ${themeGet("fonts.workSans")};
  cursor: pointer;
  &:disabled {
    pointer-events: none;
    cursor: not-allowed;
    opacity: 0.8;
  }
  ${space};
  ${color};
  ${layout};
  ${typography};
  ${flexbox};
  ${position};
  ${props =>
    variant({
      variants: {
        primary: {
          backgroundColor: "white",
          color: props.theme.colors.darkGreen,
          boxShadow: `inset 0 0 0 2px ${props.theme.colors.darkGreen}`,
          "&:hover, &:active, &:focus": {
            backgroundColor: darken(0.1, props.theme.colors.darkGreen),
            color: "white",
          },
        },
        cyber: {
          backgroundColor: props.theme.colors.darkGreen,
          color: props.theme.colors.white,
          boxShadow: `inset 0 0 0 2px ${props.theme.colors.cyberGreen}`,
          "&:hover, &:active, &:focus": {
            backgroundColor: props.theme.colors.cyberGreen,
            color: props.theme.colors.darkGreen,
          },
        },
      },
    })}
`;

Button.displayName = "Button";

Button.defaultProps = {
  as: "button",
  variant: "primary",
  fontSize: "default",
  fontWeight: "medium",
  py: "12px",
  display: "inline-block",
};

Button.propTypes = {
  ...propTypes.space,
  ...propTypes.color,
  ...propTypes.layout,
  ...propTypes.typography,
  ...propTypes.flexbox,
  ...propTypes.position,
};

export { Button };
